import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { HeaderQuery } from "../generated/graphql";

import logoHeader from "../assets/images/logo-header.svg";
import closeIcon from "../assets/images/icon-close-black.svg";
import menuIcon from "../assets/images/icon-menu-black.svg";

import "./shell.scss";
import { Footer } from "../components/footer";

interface IShellProps {
  rootActive?: boolean;
  noFooter?: boolean;
}

const Shell: React.FunctionComponent<React.PropsWithChildren<IShellProps>> = ({
  rootActive,
  noFooter,
  children,
}) => {
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  // Codegen will append 'query' onto any query name so just use the root word as below.
  // Write your gql query, save the file and your typings will be generated automatically.
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `);

  const siteName = data.site!.siteMetadata!.siteName;

  return (
    <>
      <Helmet defaultTitle={siteName || ""} titleTemplate={`%s — ${siteName}`}>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://www.schema.org",
            "@type": "Organization",
            "name": "HOP",
            "url": "https://www.homeofproduction.com",
            "logo": "https://www.homeofproduction.com/images/logo.png",
            "description": "Next generation film studios, for producers, by producers"
          }
        `}
        </script>
      </Helmet>
      {mobileMenuVisible && <div className="menu-blocker" />}
      <header>
        <div className="fixed-width">
          <Link className="logo" to="/">
            <img src={logoHeader} />
          </Link>
          <button
            className="mobile-menu-button blank"
            onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          >
            <img src={mobileMenuVisible ? closeIcon : menuIcon} />
          </button>
          <nav className="main-nav" data-visible={mobileMenuVisible}>
            <Link to="/studios" className={rootActive ? "active" : ""}>
              Studios
            </Link>
            <Link activeClassName="active" to="/team">
              Team
            </Link>
            <Link activeClassName="active" to="/news">
              News
            </Link>
            <Link className="button" to="/get-in-touch">
              Get in touch
            </Link>
          </nav>
        </div>
      </header>
      {rootActive && (
        <div className="sub-nav">
          <div className="fixed-width">
            <h2>Hop Bedfordshire</h2>
            <nav>
              <Link to="/studios">Overview</Link>
              <Link to="/studios/for-work">For work</Link>
              <Link to="/studios/for-life">For life</Link>
              <Link to="/studios/for-everyone">For everyone</Link>
              <Link to="/studios/for-sustainability">For sustainability</Link>
              <Link to="/studios/getting-there">Getting there</Link>
              <Link to="/studios/timeline">Timeline</Link>
            </nav>
          </div>
        </div>
      )}
      <main data-studios={rootActive}>{children}</main>
      {!noFooter && <Footer />}
    </>
  );
};

export default Shell;
