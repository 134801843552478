import { ClassNames } from "@rocketmakers/armstrong-edge";
import { Link } from "gatsby";
import * as React from "react";

import logoWhite from "../assets/images/logo-white.svg";
import iconLinkedIn from "../assets/images/icon-linkedin.svg";
import iconInstagram from "../assets/images/icon-instagram.svg";

import * as styles from "./footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className="fixed-width">
        <Link className={styles.logo} to="/">
          <img src={logoWhite} />
        </Link>
      </div>
      <hr />
      <div className={ClassNames.concat("fixed-width", styles.footerNav)}>
        <div>
          <strong>Website</strong>
          <Link to="/">Home</Link>
          <Link to="/studios">Studios</Link>
          <Link to="/team">Team</Link>
          <Link to="/news">News</Link>
          <Link to="/get-in-touch">Contact</Link>
        </div>
        <div>
          {/* <strong>Useful links</strong>
          <a target="_blank" href="https://www.hopbedfordshire.com">hopbedfordshire.com</a> */}
        </div>
        <div className={styles.footerSignup}>
          <strong>For latest updates</strong>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input name="email" type="email" placeholder="Your email" />
            <input type="hidden" name="form-name" value="contact" />
            <button type="submit">Signup</button>
          </form>
        </div>
      </div>
      <hr />
      <div className={ClassNames.concat("fixed-width", styles.footerBottom)}>
        <div className={styles.links}>
          <div>© Home of Production Ltd</div>
          <Link to="/legals/privacy-policy">Privacy</Link>
          <Link to="/legals/anti-slavery">UK Modern Slavery Act</Link>
        </div>
        <div className={styles.socials}>
          <div>Follow us:</div>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/home-of-production-hop/"
          >
            <img src={iconLinkedIn} />
          </a>
          <a target="_blank" href="https://www.instagram.com/hopfilmstudios/">
            <img src={iconInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};
